import './App.css';
import {
  Routes,
  Route,
} from "react-router-dom";
import Home from './components/views/Home/Home';

export const server = "http://api.lopus.io:3001"

const currentPath = window.location.pathname;

const App: React.FC = () => {
  return (
    <div>
      <Routes>
        <Route path='/' element={< Home />} />
      </Routes>
    </div>
  );
}

export default App;